import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";
import StyledText from "./StyledText";
import ArrowLeft from "./ArrowLeft";
import { Link } from "gatsby";
import VideoWithCover from "./VideoWithCover";
import ArrowUp from "./ArrowUp";
import Video from "./Video";
import ExploreIcon from "./ExploreIcon";
import ArrowRight from "./ArrowRight";
import VideoHLS from "./VideoHls";
import { getMuralArtistsNames } from "../helpers/getMuralArtistNames";
import { getMuralSlug } from "../helpers/getMuralSlug";
import VideoPlayer from "./VideoPlayer";

const Mural = ({ mural, prevMural, nextMural }) => {
  const muralImage = getImage(mural.image.imageFile.childImageSharp.gatsbyImageData);

  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    if ( window.location.hash.substring(1) === "content" ) {
      setShowArrow(true);
    }
  }, []);

  const isLandscape = mural.image.width > mural.image.height;

  return (
    <StyledMural>
      <header>
        {showArrow && (
          <Link to="/" title="The Outlaw Ocean Mural Project">
            <ArrowLeft />
          </Link>
        )}
        <h1>{mural.title}</h1>
      </header>
      <StyledMuralVideo>
        <VideoPlayer
          src={mural.video_hls_url}
          poster={mural.video_poster_url}
        >
          {mural?.video_subtitles_file?.filename_download && (
            <track label="English" kind="subtitles" srcLang="en" src={`/subtitles/${mural.video_subtitles_file.filename_download}`} />
          )}
        </VideoPlayer>
        {/*
        <VideoHLS
          poster={mural?.video_poster_url}
          src={mural?.video_hls_url}
          controls="controls"
        >
          <track label="English" kind="subtitles" srcLang="en" src={`/subtitles/${mural.slug}-EN.vtt`} default />
        </VideoHLS>
        */}
      </StyledMuralVideo>
      {/*}
      <StyledMuralVideo>
        <div>
         <iframe src={`${mural.video_embed_url}?autoplay=false&preload=false&captions=EN`} loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
        </div>
        {<VideoWithCover
          coverSrc={mural.video_cover_url}
          iframeSrc={mural.video_embed_url}
          iFrame={<iframe src={`${mural.video_embed_url}?autoplay=true&preload=false`} loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>}
          maxWidth="54em"
        />}
      </StyledMuralVideo>
        */}
      {mural.override_promo ? (
        <StyledMuralColumns>
            <StyledMuralArtist>
              {mural.artists.map((artist, index) => (
                <>
                  <figure key={index}>
                    <GatsbyImage image={getImage(artist.mural_artists_id.profile_image.imageFile.childImageSharp.gatsbyImageData)} alt={artist.mural_artists_id.name} />
                  </figure>
                  <h2>{artist.mural_artists_id.override_title ? artist.mural_artists_id.override_title : "Artist"} &mdash; <strong>{artist.mural_artists_id.name}</strong></h2>
                </>
              ))}
            </StyledMuralArtist>
            <StyledPromo>
              <ReactMarkdown>{mural.override_promo}</ReactMarkdown>
            </StyledPromo>
          </StyledMuralColumns>
      ) : (
        <>
          {mural.artists.map((artist, index) => (
            <StyledMuralColumns key={index}>
              <StyledMuralArtist>
                {artist.mural_artists_id.profile_image?.imageFile && (
                  <figure>
                    <GatsbyImage image={getImage(artist.mural_artists_id.profile_image.imageFile.childImageSharp.gatsbyImageData)} alt={artist.mural_artists_id.name} />
                  </figure>
                )}
                <h2>{artist.mural_artists_id.override_title ? artist.mural_artists_id.override_title : "Artist"} &mdash; <strong>{artist.mural_artists_id.name}</strong></h2>
              </StyledMuralArtist>
              <StyledPromo as="blockquote">
                <ReactMarkdown>{artist.mural_artists_id.promo}</ReactMarkdown>
              </StyledPromo>
            </StyledMuralColumns>
          ))}
        </>
      )}

      <StyledMuralFigure landscape={isLandscape ? true : undefined}>
        <div>
          <div style={{ "--aspect" : (mural.image.width / mural.image.height)}}>
            <GatsbyImage image={muralImage} alt={`${mural.title} by ${getMuralArtistsNames(mural)}`} />
          </div>
          {isLandscape && <small>Swipe to view <ArrowUp /></small>}
        </div>
        <figcaption>
          <StyledLocation>MURAL LOCATION &mdash; <a href={mural.google_map_url} title="Open in Google maps" target="_blank" rel="noopener noreferrer">{mural.city}, {mural.country}</a></StyledLocation>
        </figcaption>
      </StyledMuralFigure>
      <StyledText>
        <h2>Artist Bio{mural.artists.length > 1 ? "s" : ""} &mdash;</h2>
        {mural.override_bio ? (
          <ReactMarkdown>{mural.override_bio}</ReactMarkdown>
        ) : (
          <>
            {mural.artists.map((artist, index) => (
              <>
                {index > 0 && <h2>&mdash;</h2>}
                <ReactMarkdown>{artist.mural_artists_id.bio}</ReactMarkdown>
              </>
            ))}
          </>
        )}
      </StyledText>
      <StyledOptions>
        <StyledNudge>
          <Link to={`/murals/${getMuralSlug(prevMural)}`} title="Previous mural"><ArrowLeft /> Previous mural</Link>
          <Link to="/murals" title="Explore all murals"><ExploreIcon /> Explore all murals</Link>
          <Link to={`/murals/${getMuralSlug(nextMural)}`} title="Next mural"><ArrowRight /> Next mural</Link>
        </StyledNudge>
        <StyledText>
          <p>Murals added monthly</p>
        </StyledText>
      </StyledOptions>
    </StyledMural>
  );
};

export default Mural;


const StyledNudge = styled.nav`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 1em;
  margin-bottom: 1.5em;
  a {
    display: grid;
    grid-template: 1fr / 3em 1fr;
    align-items: center;
    font-size: var(--text-size);
    font-weight: var(--text-weight);
    svg,
    div {
      font-size: 1.125em;
    }
    .can-hover &:hover,
    &:active {
      opacity: 0.5;
    }
  }
`

const StyledMuralColumns = styled.div`
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 3em 0;
  @media ( min-width: 60em ) {
    grid-template: auto / 1fr 1fr;
    grid-column: 1 / span 2;
    grid-gap: 6em 0;
  }
`;
const StyledOptions = styled.div`
  p {
    opacity: 0.5;
  }
  @media ( min-width: 60em ) {
    p {
      position: relative;
      transform: rotate(-90deg) translate3d(-110%,40%,0);
      transform-origin: left center;
      display: inline-block;
      line-height: 1;
    }
  }
  @media ( min-width: 60em ) {
    position: sticky;
    top: 3em;
    align-self: start;
    grid-row: 5;
    ${StyledMuralColumns} + ${StyledMuralColumns} ~ & {
      grid-row: 6;
    }
    ${StyledMuralColumns} + ${StyledMuralColumns} + ${StyledMuralColumns} ~ & {
      grid-row: 7;
    }
    ${StyledMuralColumns} + ${StyledMuralColumns} + ${StyledMuralColumns} + ${StyledMuralColumns} ~ & {
      grid-row: 8;
    }
    ${StyledMuralColumns} + ${StyledMuralColumns} + ${StyledMuralColumns} + ${StyledMuralColumns} + ${StyledMuralColumns} ~ & {
      grid-row: 9;
    }
  }
`


const StyledMuralVideo = styled.div`
  position: relative;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  /*
  > div {
    position: relative;
    padding-bottom: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
      display: block;
    }
    video {
      position: absolute;
      display: block;
      top: 0;
      width: 100vw;
      bottom: 0;
      left: 0;
    }
  }
  */
  @media ( min-width: 60em ) {
    grid-column: 1 / span 2;
  }
`;

const StyledLocation = styled.p`
  font-size: var(--text-size);
  font-weight: var(--text-weight);
  margin: 1em 0 0;

  a[href] {
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 0.1em;
    &:hover {
      opacity: 0.5;
    }
  }
`;

const StyledMuralFigure = styled.figure`
  position: relative;
  left: 50%;
  width: 100vw;
  margin-left: -50vw;
  figcaption {
    padding: 0 var(--gutter);
  }
  small {
    display: none;
  }
  @media ( min-width: 60em ) {
    figcaption {
      padding-left: 50vw;
    }
  }
  
  ${props => props.landscape === true && css`
    @media ( max-width: 48em ) {
      > div {
        position: relative;
        height: 34em;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        &::-webkit-scrollbar {
          display: none;
        }
        > div {
          position: relative;
          height: 100%;
          width: calc(34em * var(--aspect));
        }
        .gatsby-image-wrapper {
          height: 100% !important;
          width: auto !important;
          max-width: none !important;
        }
      }
      small {
        position: absolute;
        display: flex;
        align-items: center;
        top: 50%;
        left: 0;
        padding: 0.625em 0.75em 0.5em 0.625em;
        background-color: var(--color-background);
        font-size: 0.75em;
        font-weight: 500;
        text-transform: uppercase;
        transform: rotate(-90deg) translate3d(-50%,50%,0);
        transform-origin: left center;
        line-height: 1;
        svg {
          margin-left: 0.5em;
          margin-top: -0.125em;
        }
      }
    }
  `}
`;

const StyledMuralArtist = styled.div`
  @media ( min-width: 60em ) {
    position: sticky;
    top: 3em;
    align-self: start;
    padding-top: 0.625em;
    padding-right: 40%;
  }
  h2 {
    font-size: var(--text-size);
    font-weight: var(--text-weight);
    margin: 1em 0 0;
    text-transform: uppercase;
    strong {
      font-weight: inherit;
      text-transform: none;
    }
  }
  figure {
    position: relative;
    height: 0;
    padding-bottom: 120%;
    overflow: hidden;
    .gatsby-image-wrapper {
      position: absolute !important;
      top: 0;
      left: 0;
      height: 100% !important;
      width: 100% !important;
    }
    &:not(:first-of-type) {
      margin-top: 2em;
    }
  }
`;

const StyledPromo = styled(StyledText)`
  &:is(blockquote) p:first-of-type::before {
    content: "“";
    margin-left: -0.3875em;
  }
  &:is(blockquote) p:last-of-type::after {
    content: "”";
  }
  /*
  cite {
    display: block;
    font-style: normal;
    margin-top: 1em;
  }
  */
`;


const StyledMural = styled.article`
  padding: 1.5em 0 3em;
  display: grid;
  grid-template: auto / 1fr;
  grid-gap: 3em 0;
  header {
    a {
      svg {
        font-size: 1.75em;
      }
      .can-hover &:hover,
      &:active {
        opacity: 0.5;
      }
    }
    h1 {
      font-size: 2.5em;
      text-transform: uppercase;
      //font-weight: 800;
      line-height: 0.95;
      margin: 0.5em 0 0;
    }
  }
  @media ( min-width: 48em ) {
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      a {
        justify-self: start;
        line-height: 0;
        svg {
          font-size: 3.125em;
          margin-top: 0.0625em;
        }
      }
      h1 {
        font-size: 4.75em;
        margin: 0;
      }
    }
  }
  @media ( min-width: 60em ) {
    grid-template: auto / 1fr 1fr;
    grid-gap: 6em 0;
    padding: 6em 0;
    > header {
      display: grid;
      grid-template: inherit;
      grid-column: 1 / span 2;
      h1 {
        grid-column: 2;
      }
    }
    > figure {
      grid-column: 1 / span 2;
    }
    > ${StyledText} {
      grid-column: 2;
    }
  }
`;