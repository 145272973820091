import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import styled, { css } from "styled-components";

const VideoHLS = ({ src, poster, children, customPadding, fitToContainer, muted = undefined, autoPlay = undefined, loop = undefined, controls = undefined }) => {

  const videoRef = useRef();

  useEffect(() => {
    var video = videoRef.current;
    var videoSrc = src;
    if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
    } else if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
        console.log(
          data
        );
      });
      console.log(hls.subtitleTracks)
      hls.subtitleDisplay = true;
    }
  });
  return (
    <StyledVideo fitToContainer={fitToContainer} customPadding={customPadding}>
      <div>
        <video
          ref={videoRef}
          playsInline
          controls={controls}
          controlsList="nodownload"
          poster={poster}
          preload="auto"
          autoPlay={autoPlay}
          muted={muted}
          loop={loop}
        >
          {children}
        </video>
      </div>
    </StyledVideo>
  );
};
export default VideoHLS;

const StyledVideo = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  ${props => props.fitToContainer && css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `}
  div {
    position: relative;
    width: 100%;
    background-color: black;
    padding-bottom: ${props => props.customPadding ? props.customPadding : '56.25%'};
    overflow: hidden;
    ${props => props.fitToContainer && css`
      position: absolute;
      height: 100%;
      width: 100%;
      padding: 0;
    `}
  }
  video {
    position: absolute;
    display: block;
    object-fit: cover;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
`