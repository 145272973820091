import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Mural from "../components/Mural";
import { Helmet } from "react-helmet";
import ArrowLeft from "../components/ArrowLeft";
import ArrowRight from "../components/ArrowRight";
import Meta from "../components/Meta";
import ExploreIcon from "../components/ExploreIcon";
import { getMuralArtistsNames } from "../helpers/getMuralArtistNames";

const MuralTemplate = ({ data }) => {
  const mural = data.directus.murals_by_id;
  const murals = data.directus.murals;

  const currentIndex = murals.findIndex((item) => item.id === mural.id );
  
  const prevIndex = currentIndex === 0 ? murals.length - 1 : currentIndex - 1;
  const nextIndex = currentIndex === murals.length - 1 ? 0 : currentIndex + 1;

  const prevMural = murals[prevIndex];
  const nextMural = murals[nextIndex];

  return (
    <>
      <Meta
        title={`${mural.title} by ${getMuralArtistsNames(mural)} for The Outlaw Ocean Mural Project`}
        image={mural.image.imageFile.publicURL}
      />
      <Mural mural={mural} prevMural={prevMural} nextMural={nextMural} />
      {/*<StyledPagination>
        <Link to={`/murals/${prevMural.slug}-by-${prevMural.artist.slug}#content`} title="Previous mural"><ArrowLeft /></Link>
        <Link to="/murals" title="Explore all murals"><ExploreIcon /></Link>
        <Link to={`/murals/${nextMural.slug}-by-${nextMural.artist.slug}#content`} title="Next mural"><ArrowRight /></Link>
  </StyledPagination>*/}
    </>
  );
};

export default MuralTemplate;

const StyledPagination = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2em 0 4em;
  a {
    font-size: 1.5em;
    display: flex;
    align-items: center;
    .can-hover &:hover,
    &:active {
      opacity: 0.5;
    }
  }
  @media ( min-width: 48em ) {
    a {
      font-size: 2.5em;
    }
  }
  @media ( min-width: 60em ) {
    margin-bottom: 6em;
    padding-left: 50%;
  }
`;


export const query = graphql`
  query($id: ID!) {
    directus {
      murals_by_id(id: $id) {
        artists {
          mural_artists_id {
            id
            bio
            name
            override_title
            promo
            profile_image {
              id
              imageFile {
                childImageSharp {
                  gatsbyImageData(height: 1024)
                }
              }
            }
          }
        }
        city
        country
        google_map_url
        id
        image {
          id
          height
          imageFile {
            childImageSharp {
              gatsbyImageData(width: 2048, quality: 80)
            }
            publicURL
          }
          width
        }
        override_promo
        override_bio
        override_artist_name
        slug
        title
        video_hls_url
        video_poster_url
        video_cover_url
        video_subtitles_file {
          filename_download
        }
      }
      murals(
        filter: {
          status: {_eq: "published"}
        }
      ) {
        artists {
          mural_artists_id {
            id 
            slug
          }
        }
        id
        slug
        override_artist_slug
      }
    }
  }
`;