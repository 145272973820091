
import React from "react";
import styled from "styled-components";

const ArrowLeft = ({ size = undefined }) => (
  <StyledArrow xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.99 7.51" size={size}>
    <polygon points="5.24 0 4.53 0.71 7.08 3.26 0 3.26 0.01 4.26 7.08 4.26 4.53 6.8 5.24 7.51 8.99 3.75 5.24 0" />
  </StyledArrow>
);

export default ArrowLeft;

const StyledArrow = styled.svg`
  position: relative;
  display: inline-block;
  height: ${props => props.size ? `${props.size}rem` : "1em"};
  width: ${props => props.size ? `${props.size}rem` : "1em"};
  transform: rotate(180deg);
  polygon {
    fill: currentColor;
  }
`;