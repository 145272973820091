import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Video from "./Video";
//import Credit from "./styled/credit";
import PulseNotice from "./PulseNotice";


const VideoWithCover = ({ coverSrc = undefined, coverImage = undefined, videoSrc = undefined, title, subtitle, credit, iFrame = undefined, iframeSrc = undefined, maxWidth = undefined }) => {
  const [hideCover, setHideCover] = useState(false);

  useEffect(() => {
    setHideCover(false);
  }, [coverSrc]);

  const [iframeSource, setIframeSource] = useState(iframeSrc)

  useEffect(() => {
    setIframeSource(iframeSrc)
  }, [iframeSrc])



  return (
    <StyledVideoWithCover maxWidth={maxWidth}>
      {hideCover ? (
        <StyledVideoEmbed>
          <div>
            <iframe src={`${iframeSrc}?autoplay=true`} loading="lazy" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe>
          </div>
        </StyledVideoEmbed>
      ) : (
        <StyledVideoCover onClick={() => setHideCover(true)}>
          {coverSrc && (
            <Video 
              src={coverSrc}
              autoplay="autoplay"
              loop="loop"
              muted="muted"
              customPadding="56.25%"
              maxWidth={maxWidth}
              hideLoading="true"
            />
          )}
          {coverImage && (
            <StyledBackground style={{backgroundImage: `url(${coverImage})`}} />
          )}
          <figcaption>
            {title && <h2>{title}</h2>}
            {subtitle && <p>{subtitle}</p>}
            <div><PulseNotice>Watch Video</PulseNotice></div>
          </figcaption>
        </StyledVideoCover>

      )}
      {/*! hideCover && credit && (
        <Credit>{credit}</Credit>
      )*/}
    </StyledVideoWithCover>
  );
};

export default VideoWithCover;

const StyledVideoWithCover = styled.figure`
  position: relative;
  width: 100%;
  //max-width: ${props => props.maxWidth ? props.maxWidth : 'var(--text-max-width)'};
  margin: 0 auto;
`;
export { StyledVideoWithCover };

const StyledVideoCover = styled.div`
  position: relative;
  cursor: pointer;
  .can-hover &:hover,
  &:active {
    box-shadow: 0 0 0 0.125em var(--color-text-light);
  }
  figcaption {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding: 1em 1em var(--gutter);
    text-align: center;
    color: var(--color-text-light);
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--color-background-dark);
      background: radial-gradient(var(--color-background-dark), transparent);
      opacity: 0.4;
      transition: opacity 0.2s ease;
    }
    .can-hover &:hover::before {
      opacity: 0;
    }
    h2 {
      position: relative;
      font-family: var(--font-bold);
      font-weight: var(--font-bold-weight);
      text-transform: uppercase;
      font-size: 3em;
      margin: 0;
      line-height: 1;
    }
    p {
      position: relative;
      margin: 0.5em 0 0;
    }
    div {
      position: absolute;
      display: block;
      width: 100%;
      text-align: center;
      bottom: 0.5em;
      color: var(--color-text-light);
      top: 2em;
      &:first-child:last-child {
        top: 50%;
        bottom: auto;
        margin-top: -0.375em;
      }
    }
  }
`


const StyledVideoEmbed = styled.div`
  position: relative;
  margin: 0 auto;
  > div {
    position: relative;
    padding-bottom: 56.25%;
    background-color: black;
    iframe {
      border: none; 
      position: absolute; 
      top: 0;
      left: 0;
      height: 100%; 
      width: 100%;
    }
  }
`

const StyledBackground = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  background-size: cover;
  background-position: center center;
`
