import React, { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components";
import Hls from "hls.js";

const VideoPlayer = ({ 
  src, 
  muted = undefined, 
  loop = undefined, 
  autoplay = undefined, 
  onEnded = undefined,
  hasSound = undefined, 
  preload = "auto", 
  credit = undefined,
  soundControl = undefined,
  controls= undefined,
  poster = undefined,
  setHasPlayedVideo = undefined,
  overlayText = undefined,
  exteriorForcePause = undefined,
  children
}) => {


  const videoRef = useRef();

  useEffect(() => {
    const video = videoRef.current;
    const videoSource = src;
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoSource);
      hls.attachMedia(video);
      hls.subtitleDisplay = true;
      /*hls.on(Hls.Events.MANIFEST_PARSED, () => {
        video.play();
      });*/
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSource;
      /*video.addEventListener('loadedmetadata', () => {
        video.play();
      });*/
    } else {
      console.error('HLS is not supported in this browser');
    }
  }, []);

  const [aspectRatio, setAspectRatio] = useState(0.5625);
  useEffect(() => {
    const video = videoRef.current;
    video.addEventListener('loadedmetadata', () => {
      const aspectRatio = video.videoWidth / video.videoHeight;
      //const track = video.videoTracks[0];
      //const settings = track.getSettings();
      //const aspectRatio = settings.aspectRatio;
      console.log(`The aspect ratio of the video is ${aspectRatio}`);
    });
  }, []);


  const [canPlayThrough, setCanPlayThrough] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (video.readyState >= 4) {
      setCanPlayThrough(true);
    } else {
      video.addEventListener('canplaythrough', () => {
        setCanPlayThrough(true);
      });
    }
  }, []);
  


  const [isPlaying, setIsPlaying] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isMuted, setIsMuted] = useState(muted);
  const [volume, setVolume] = useState(1);
  const [currentTime, setCurrentTime] = useState(0);

  useEffect(() => {
    const video = videoRef.current;
    video.currentTime = 0;
    setCurrentTime(0);
    setIsPlaying(false);
    setIsPaused(false);
    setIsEnded(false);
    if (video.readyState >= 4) {
      setCanPlayThrough(true);
    } else {
      video.addEventListener('canplaythrough', () => {
        setCanPlayThrough(true);
      });
    }
  }, [src]);

  useEffect(() => {
    const video = videoRef.current;

    video.addEventListener('play', () => {
      setIsPlaying(true);
      setIsPaused(false);
      setIsEnded(false);
      if (setHasPlayedVideo) {
        setHasPlayedVideo(true);
      }
    });
    video.addEventListener('pause', () => {
      setIsPlaying(false);
      setIsPaused(true);
      setIsEnded(false);
    });
    video.addEventListener('ended', () => {
      setIsPlaying(false);
      setIsPaused(false);
      setIsEnded(true);
      if (onEnded) {
        onEnded();
      }
    });
    video.addEventListener('volumechange', () => {
      setIsMuted(video.muted);
    });
  }, []);

  useEffect(() => {
    if (exteriorForcePause) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [exteriorForcePause]);

  const handlePlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVolumeChange = (event) => {
    const value = parseFloat(event.target.value);
    setVolume(value);
    videoRef.current.volume = value;
    setIsMuted(value === 0);
  };

  const handleMuteToggle = () => {
    const newVolume = isMuted ? volume || 0.5 : 0;
    videoRef.current.volume = newVolume;
    setIsMuted(!isMuted);
    setVolume(newVolume);
  };

  const handleSeek = (event) => {
    const value = parseFloat(event.target.value);
    videoRef.current.currentTime = value;
    setCurrentTime(value);
  };

  const handleRestart = () => {
    videoRef.current.currentTime = 0;
    setCurrentTime(0);
    setIsPlaying(true);
    videoRef.current.play();
  };

  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
  };

  const getProgress = () => {
    if (videoRef.current && videoRef.current.duration) {
      return ((currentTime / videoRef.current.duration) * 100).toFixed(4);
    }
    return 0;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time - minutes * 60);
    const minutesString = minutes < 10 ? `${minutes}` : minutes;
    const secondsString = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutesString}:${secondsString}`;
  };

  const className = function () {
    let className = "";
    if ( canPlayThrough ) {
      className = "can-play-through";
    }
    if ( isPlaying ) {
      className = "is-playing";
    }
    if ( isPaused ) {
      className += " is-paused";
    }
    if ( isEnded ) {
      className += " is-ended";
    }
    if ( overlayText ) {
      className += " has-overlay-text";
    }
    return className;
  }

  function playFullScreenVideo() {
    const videoElement = videoRef.current;
    // Check if full screen mode is supported
    if (videoElement.requestFullscreen) {
      // Request full screen mode
      videoElement.requestFullscreen();
    } else if (videoElement.webkitRequestFullscreen) {
      // Request full screen mode for Safari
      videoElement.webkitRequestFullscreen();
    }
  
    // Play the video
    //if ( videoElement.paused)
    //videoElement.play();
  }

  function handleCueChange(e) {
    if ( this?.activeCues[0]?.text ) {
      setCurrentCue(this.activeCues[0].text);
    }
    else {
      setCurrentCue('');
    }
  }
  const [currentCue, setCurrentCue] = useState(null);
  useEffect(() => {
    if ( ! videoRef.current )
      return;

    const video = videoRef.current;
    const track = video?.textTracks[0] || undefined;
    if ( ! track )
      return;
    track.mode = "hidden";
    track.oncuechange = handleCueChange;
    
  }, []);

  return (
    <StyledVideoFigure className={className()}>
      <StyledVideoPlayer>
        <StyledVideoElement aspectRatio={aspectRatio}>
          <video 
            ref={videoRef}
            src={src} 
            playsInline
            autoPlay={autoplay}
            muted={isMuted}
            onEnded={onEnded}
            onTimeUpdate={handleTimeUpdate}
            loop={loop}
            preload={preload}
            controls={controls}
            poster={poster}
          >
            {children}
          </video>
          {currentCue && currentCue !== "" && (
            <StyledVideoCaptions className={! currentCue || currentCue === "" ? "is-empty" : undefined}>
              <span>{currentCue}</span>
            </StyledVideoCaptions>
          )}
        </StyledVideoElement>
        <StyledVideoPlayPauseOverlay className={className()} onClick={handlePlayPause}>
          <StyledControlPlayPause 
            className={className()} 
            isPlaying={isPlaying} 
            title={isPlaying ? "Pause video" : "Play video"} 
            isLarge={true}
          />
        </StyledVideoPlayPauseOverlay>
        <StyledVideoControls>
          <StyledControlPlayPause 
            onClick={handlePlayPause} 
            className={className()} 
            isPlaying={isPlaying} 
            title={isPlaying ? "Pause video" : "Play video"} 
          />
          {/*<input type="range" min={0} max={1} step={0.01} value={volume} onChange={handleVolumeChange} />
          <button onClick={handleMuteToggle}>{isMuted ? 'Unmute' : 'Mute'}</button>*/}
          <StyledRangeControl>
            <span style={{"--transform": `${getProgress()}%`}} />
            <input type="range" min={0} max={videoRef.current?.duration || 0} value={currentTime} onChange={handleSeek} />
          </StyledRangeControl>
          <time>
            <span>{formatTime(currentTime)}</span> / <span>{formatTime(videoRef.current?.duration || 0)}</span>
          </time>
          {/*<button onClick={handleRestart}>Restart</button>*/}
        </StyledVideoControls>
      </StyledVideoPlayer>
      {overlayText && (
        <StyledVideoCaption className={className()} onClick={handlePlayPause}>
          <p>{overlayText}</p>
        </StyledVideoCaption>
      )}
      {/*credit && (
        <Credit>{credit}</Credit>
      )*/}
      {/*}
      {hasSound && (
        <StyledSoundButton onClick={toggleSound} controlPosition={soundControl}>
          {sound && <SoundOn />}
          {! sound && <SoundOff />}
        </StyledSoundButton>
      )}
      {overlayText && (
        <StyledOverlayText className={hasPlayed ? "has-played" : undefined}>
          <p>{overlayText}</p>
        </StyledOverlayText>
      )}
      */}
      {/*<button onClick={playFullScreenVideo}>Play full screen</button>*/}
    </StyledVideoFigure>
  )
};

export default VideoPlayer;


const StyledSoundButton = styled.span`
  position: relative;
  display: block;
  z-index: 2;
  margin: 1em auto 0;
  height: 2em;
  width: 2em;
  opacity: 0.7;
  .can-hover &:hover,
  &:active {
    opacity: 1;
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
    polygon,
    path,
    rect {
    }
  }
  ${props => props.controlPosition === 'right' && css`
    @media ( max-width: 48em ) {
      position: absolute;
      left: 50%;
      bottom: calc(100% + 1.25em);
      margin: 0 0 0 -1em;
    }
    @media ( min-width: 48em ) {
      position: absolute;
      top: 50%;
      left: calc(100% + 1.25em);
      margin: -1.25em 0 0;
    }
  `}
`

const SoundOff = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22"><path d="M11.39,12.87,17,8.17V24.06l-5.62-4.71H7.93V12.87Zm11.79.72-1.8,1.8-1.8-1.8-.73.72,1.8,1.8-1.8,1.8.73.73,1.8-1.8,1.8,1.8.72-.73-1.8-1.8,1.8-1.8Zm9,2.52A16.11,16.11,0,1,1,16.11,0,16.12,16.12,0,0,1,32.22,16.11Zm-1,0A15.09,15.09,0,1,0,16.11,31.2,15.11,15.11,0,0,0,31.2,16.11Z" fill="#fff"/></svg>
);

const SoundOn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22"><rect x="20.63" y="11.28" width="1.02" height="9.66" fill="#fff"/><polygon points="7.93 12.87 7.93 15.77 7.93 16.46 7.93 19.35 11.39 19.35 17.01 24.06 17.01 8.16 11.39 12.87 7.93 12.87" fill="#fff"/><rect x="18.39" y="13.33" width="1.02" height="5.57" fill="#fff"/><path d="M16.11,0A16.11,16.11,0,1,0,32.22,16.11,16.13,16.13,0,0,0,16.11,0Zm0,31.2A15.09,15.09,0,1,1,31.2,16.11,15.11,15.11,0,0,1,16.11,31.2Z" fill="#fff"/><rect x="22.88" y="9.11" width="1.02" height="14.01" fill="#fff"/></svg>
);

/*
const Play = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22"><path d="M11.94,24V8.24l11.32,7.87Zm20.28-7.87A16.11,16.11,0,1,1,16.11,0,16.12,16.12,0,0,1,32.22,16.11Zm-1,0A15.09,15.09,0,1,0,16.11,31.2,15.11,15.11,0,0,0,31.2,16.11Z" fill="#fff"/></svg>
);

const Pause = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.22 32.22"><path d="M16.11,0A16.11,16.11,0,1,0,32.22,16.11,16.13,16.13,0,0,0,16.11,0Zm0,31.2A15.09,15.09,0,1,1,31.2,16.11,15.11,15.11,0,0,1,16.11,31.2Zm-2-8.41H10.84V9.43h3.27Zm7.27,0H18.12V9.43h3.26Z" fill="#fff"/></svg>
)
*/

const StyledRangeControl = styled.div`
  position: relative;
  height: 0.5em;
  display: grid;
  align-items: center;
  span {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    contain: paint;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background-color: currentColor;
    }
    &::before {
      right: 0;
      opacity: 0.5;
    }
    &::after {
      right: 100%;
      transform: translate3d(var(--transform), 0, 0);
    }
  }
  input[type=range] {
    /* Define reusable variables */
    --thumb-size: 1em;
    --thumb-color: currentColor;
    --thumb-radius: 0;
    --thumb-shadow: 0;
    --track-height: 0.5em;
    --track-color: #ddd;
    --track-radius: 4px;

    position: relative;
    display: block;
    font-size: inherit;
    margin: 0;
    background: transparent;

    cursor: pointer;
    -webkit-appearance: none;
    width: 100%;

    &:focus {
      outline: none;
    }

    /* Define thumb styles */
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: var(--thumb-size);
      height: var(--thumb-size);
      background-color: var(--thumb-color);
      border-radius: var(--thumb-radius);
      box-shadow: var(--thumb-shadow);
      position: relative;
      top: 50%;
      transform: translate3d(0,-50%,0);
      opacity: 0;
    }
    &::-moz-range-thumb {
      width: var(--thumb-size);
      height: var(--thumb-size);
      background-color: var(--thumb-color);
      border-radius: var(--thumb-radius);
      border: none;
      box-shadow: var(--thumb-shadow);
      position: relative;
      transform: translate3d(0,-0.375em,0);
      opacity: 0;
    }
    &::-ms-thumb {
      width: var(--thumb-size);
      height: var(--thumb-size);
      background-color: var(--thumb-color);
      border-radius: var(--thumb-radius);
      box-shadow: var(--thumb-shadow);
      position: relative;
      top: 50%;
      transform: translate3d(0,-50%,0);
      opacity: 0;
    }

    &::-webkit-slider-runnable-track {
      -webkit-appearance: none;
      height: var(--track-height);
      background: rgba(0,0,0,0);
      width: 100%;
    }
    &:focus::-webkit-slider-runnable-track {
      background: rgba(0,0,0,0);
    }
    &::-moz-range-track {
      height: var(--track-height);
      background: transparent;
      width: 100%;
    }
    &::-ms-track {
      width: 100%;
      height: var(--track-height);
      background: transparent; 
      border-color: transparent;
      color: transparent;
    }


  }
`;


const StyledVideoControls = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5em 0.75em 0.375em 0.5em;
  z-index: 10;
  display: grid;
  grid-template: 1fr / auto 1fr auto;
  align-items: center;
  gap: 1em;
  transform: translate3d(0,100%,0);
  transition: transform 0.3s ease;
  @media ( min-width: 48em ) {
    padding: 0.75em 1.25em 0.625em 0.75em;
    gap: 1.5em;
  }
  .is-playing & {
    transform: translate3d(0,100%,0);
  }
  .is-paused &,
  .has-played:not(.is-playing),
  .can-hover .is-playing:hover & {
    transform: translate3d(0,0,0);
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--theme-background-dark);
    opacity: 0.5;
    z-index: -1;
  }
  time {
    font-family: var(--font-heading);
    font-weight: 700;
    font-size: 0.6875em;
    margin-left: 0.5em;
    line-height: 1;
    transform: translate3d(0,1px,0)
  }
  @media ( min-width: 48em ) {
    time {
      font-size: 0.75em;
    }
  }
`;

const StyledVideoElement = styled.div`
  position: relative;
  video {
    position: relative;
    display: block;
    width: 100%;
  }
`;

const StyledVideoCaptions = styled.div`
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translate3d(-50%,0,0);
  width: 100%;
  max-width: calc(100% - 2em);
  text-align: center;
  transition: transform 0.3s ease;
  .is-paused &,
  .has-played:not(.is-playing),
  .can-hover .is-playing:hover & {
    transform: translate3d(-50%,-3.5em,0);
  }
  span {
    font-size: 1em;
    font-weight: var(--font-sans-weight);
    background-color: var(--theme-background-dark);
    color: var(--theme-text-against-dark);
    padding: 0.25em;
    box-shadow: 0.125em 0 0 0 var(--theme-background-dark), -0.125em 0 0 0 var(--theme-background-dark);
  }
  @media ( min-width: 48em ) {
    span {
      font-size: 1.5em;
    }
  }
`;


const StyledVideoPlayer = styled.div`
  position: relative;
  contain: paint;
`;

const StyledVideoFigure = styled.figure`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const StyledVideoPlayPauseOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: grid;
  padding: 1.5em;
  //justify-content: start;
  //align-items: end;
  //place-content: center;
  cursor: pointer;
  z-index: 10;
  &.is-playing,
  &.is-paused {
    > * {
      opacity: 0;
    }
  }
  @media ( max-width: 48em ) {
    place-content: center;
  }
  @media ( min-width: 48em ) {
    &.has-overlay-text {
      align-items: end;
      justify-content: start;
    }
    &:not(.has-overlay-text) {
      place-content: center;
    }
  }
`;


const StyledControlPlayPause = styled.button`
  position: relative;
  display: block;
  height: 2em;
  width: 2em;
  z-index: 2;
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
  cursor: pointer;
  &::before {
    content: '';
    position: relative;
    z-index: 3;
    display: block;
    height: calc(1.5em * 0.75);
    width: calc(1.25em * 0.75);
    background-color: currentColor;
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
    margin-left: 0.25em;
  }
  &.is-playing::before {
    clip-path: polygon(0 0, 40% 0, 40% 100%, 60% 100%, 60% 0, 100% 0, 100% 100%, 0 100%);
    margin-left: 0;
  }
  @media ( min-width: 48em ) {
    height: 2.5em;
    width: 2.5em;
    &::before {
      height: 1.5em;
      width: 1.25em;
    }
  }
  ${props => props.isLarge && css`
    transform: scale(1.75);
    border: 0.125em solid;
    border-radius: 50%;
    &::before {
      transform: scale(0.75);
    }
  `}
`;


const StyledVideoCaption = styled.figcaption`
  position: relative;
  /*
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: var(--theme-background-dark);
    opacity: 0.1;
    mix-blend-mode: multiply;
  }*/
  p {
    position: relative;
    font-family: var(--font-heading);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -0.05em;
    font-size: 1.125em;
    text-align: center;
    margin: 1em auto 0;
    max-width: 26em;
    line-height: 1.4;
    filter: drop-shadow(0 0 2px rgba(0,0,0,0.1));
    @media ( min-width: 48em ) {
      font-size: 2em;
    }
  }
  @media ( min-width: 48em ) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: grid;
    place-items: center;
    cursor: pointer;
    &.is-playing,
    &.is-paused {
      display: none;
    }
    p {
      margin-top: 0;
    }
  }
`