import styled from "styled-components";

const ExploreIcon = styled.div`
  position: relative;
  height: 0.875em;
  width: 1em;
  background-color: currentColor;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 0.25em;
    width: 100%;
    left: 0;
    background-color: var(--color-background);
  }
  &::before {
    top: 0.125em;
  }
  &::after {
    top: 0.5em;
  }
`;
export default ExploreIcon;